import { create } from '@storybook/theming'
import Logo from '../static/img/logo.svg'

export default create({
  base: 'light',

  fontBase: 'Roboto, sans-serif',
  fontCode: 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',

  brandTitle: 'Seahorse',
  brandImage: Logo,
})
